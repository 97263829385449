import React, { useState, useEffect } from "react";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import SidebarOne from "./sidebar1";
import { withRouter } from "./withRouter";
import Alert from '@mui/material/Alert';


function EditSubgroup() {
  const [sub_cat_name, setSub_catName] = useState("");
  const [cat_id, setCatId] = useState("");
  const [cat_status, setCatStatus] = useState("");
  const [file, setFile] = useState(null);
  const [existingImage, setExistingImage] = useState("");
  const [data1, setData1] = useState([]);
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});

  const api_url = localStorage.getItem('api_url');
  const sub_cat_id = localStorage.getItem("sub_cat_id");

  const validateInputs = () => {
    const errors = {};
    if (!sub_cat_name) {
      errors.sub_cat_name = "Sub Category name is required";
    }
    if (!cat_id) {
      errors.cat_id = "Category is required";
    }
    if (!cat_status) {
      errors.cat_status = "Status is required";
    }
    // if (!file) {
    //   errors.file = "Category image is required";
    // }
    return errors;
  };

  const addProductDetails = () => {

    const validationErrors = validateInputs();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const formData = new FormData();
    formData.append("sub_cat_name", sub_cat_name);
    formData.append('sub_cat_id', sub_cat_id);
    formData.append("cat_id", cat_id);
    formData.append("status", cat_status);
    if (file) {
      formData.append("pic", file);
    }

    axios.post(api_url + "/updatesubcatdetails", formData)
      .then((res) => {
        alert("Sub Categories updated successfully");
        navigate("/Subgroups", { replace: true });
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error updating subcategory:", error);
      });
  };

  useEffect(() => {
    const fetchCategory = async () => {
      try {
        const res = await axios.get(api_url + '/getsubcategory/' + sub_cat_id);
        const data = res.data.subcategories;
        setSub_catName(data.sub_cat_name);
        setCatId(data.cat_id);
        setCatStatus(data.status);
        setExistingImage(res.data.sub_cat_image);
        setData1(res.data.Category_detail);
      } catch (error) {
        console.error("Error fetching subcategory data:", error);
      }
    };
    fetchCategory();
  }, [api_url, sub_cat_id]);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  return (
    <div className="view_pro" style={{ display: 'flex' }}>
      <SidebarOne />
      <div className="pr_names">
        <div className="pro_names" style={{ display: 'flex' }}>
          <p className="p_name">Sub Categories</p>
          <p className="act_name" style={{ textAlign: 'left' }}>Active</p>
        </div>
        <hr />
        <div className="product_detail">
          <div className="item_details">
            <div style={{ display: 'flex' }}>
              <p className="item_name">Sub Category Name</p>
              <button className="itme_img11" onClick={addProductDetails}>Update</button>
            </div>
            <input type="text" className="item_input_cat" value={sub_cat_name} onChange={(e) => setSub_catName(e.target.value)} />
            {errors.sub_cat_name && <Alert severity="error">{errors.sub_cat_name}</Alert>}
          </div>
          <br />
          <div className="item_details">
            <div style={{ display: 'flex' }}>
              <p className="item_name">Category Name</p>
            </div>
            <select className="item_input_cat" value={cat_id} onChange={(e) => setCatId(e.target.value)}>
              <option value="">Select a Category Name</option>
              {data1.map((card) => (
                <option key={card._id} value={card._id}>
                  {card.cat_name}
                </option>
              ))}
            </select>
            {errors.cat_id && <Alert severity="error">{errors.cat_id}</Alert>}
          </div>
          <br />
          <div className="item_details">
            <div style={{ display: 'flex' }}>
              <p className="item_name">Sub Category Image</p> 
            </div> 
            <input type="file" onChange={handleFileChange} />
            {existingImage && !file && <img src={existingImage} alt="Current image" style={{ maxWidth: '100px', maxHeight: '100px', marginTop: '10px' }} />}
            {file && <img src={URL.createObjectURL(file)} alt="Uploaded File" style={{ maxWidth: '100px', maxHeight: '100px', marginTop: '10px' }} />}
            {errors.file && <Alert severity="error">{errors.file}</Alert>}
          </div>
          <br />
          <div className="item_details">
            <div style={{ display: 'flex' }}>
              <p className="item_name">Status</p>
            </div>
            <select className="item_input_cat" value={cat_status} onChange={(e) => setCatStatus(e.target.value)}>
              <option value="">Select status</option>
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </select>
            {errors.cat_status && <Alert severity="error">{errors.cat_status}</Alert>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(EditSubgroup);
