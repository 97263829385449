import React, { useState, useEffect } from "react";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import SidebarOne from "./sidebar1";
import Alert from '@mui/material/Alert';


function EditGroup() {
  const [cat_name, setCatName] = useState("");
  const [cat_description, setCatDescription] = useState("");
  const [cat_status, setCatStatus] = useState("");
  const [file, setFile] = useState(null);
  const [existingImage, setExistingImage] = useState("");
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});


  const api_url = localStorage.getItem('api_url');
  const cat_id = localStorage.getItem('cat_id');

  const addProductDetails = () => {


    const validationErrors = validateInputs();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const formData = new FormData();
    formData.append("cat_id", cat_id);
    formData.append("cat_name", cat_name);
    formData.append("cat_description", cat_description);
    formData.append("status", cat_status);
    if (file) {
      formData.append("pic", file);
    }

    axios.post(api_url + "/updatecatdetails", formData)
      .then((res) => {
        alert("Categories updated successfully");
        navigate("/Groups", { replace: true });
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error updating category:", error);
      });
  };

  const validateInputs = () => {
    const errors = {};
    if (!cat_name) {
      errors.cat_name = "Category name is required";
    }
    if (!cat_description) {
      errors.cat_description = "Category description is required";
    }
    if (!cat_status) {
      errors.cat_status = "Status is required";
    }
    // if (!file) {
    //   errors.file = "Category image is required";
    // }
    return errors;
  };

  useEffect(() => {
    const fetchCategory = async () => {
      try {
        const res = await axios.get(`${api_url}/getCategory/${cat_id}`);
        const data = res.data;
        setCatName(data.categories.cat_name);
        setCatDescription(data.categories.cat_description);
        setCatStatus(data.categories.status);
        setExistingImage(data.cat_image);
      } catch (error) {
        console.error("Error fetching category data:", error);
      }
    };
    fetchCategory();
  }, [api_url, cat_id]);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  return (
    <div className="view_pro" style={{ display: 'flex' }}>
      <SidebarOne />
      <div className="pr_names">
        <div className="pro_names" style={{ display: 'flex' }}>
          <p className="p_name">Categories</p>
          <p className="act_name" style={{ textAlign: 'left' }}>Active</p>
        </div>
        <hr />
        <div className="product_detail">
          <div className="item_details">
            <div style={{ display: 'flex' }}>
              <p className="item_name">Category Name</p>
              <button className="itme_img11" onClick={addProductDetails}>Update</button>
            </div>
            <input type="text" className="item_input_cat" value={cat_name} onChange={(e) => setCatName(e.target.value)} />
            {errors.cat_name && <Alert severity="error">{errors.cat_name}</Alert>}
          </div>
          <br />
          <div className="item_details">
            <div style={{ display: 'flex' }}>
              <p className="item_name">Category Description</p>
            </div>
            <textarea type="text" className="item_input_cat1" value={cat_description} onChange={(e) => setCatDescription(e.target.value)} />
            {errors.cat_description && <Alert severity="error">{errors.cat_description}</Alert>}
          </div>
          <br />
          <div className="item_details">
            <div style={{ display: 'flex' }}>
              <p className="item_name">Category Image</p>
            </div>
            <input type="file" onChange={handleFileChange} />

{existingImage && !file && <img src={existingImage} alt="Current image" />}
            {file && <img src={URL.createObjectURL(file)} alt="Uploaded File" />}
            {errors.file && <Alert severity="error">{errors.file}</Alert>}
          </div>
          <br />
          <div className="item_details">
              <div style={{ display: 'flex' }}>
                <p className="item_name">Status</p>
              </div>
              <select className="item_input_cat" value={cat_status} onChange={(e) => setCatStatus(e.target.value)}>
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
                {errors.cat_status && <Alert severity="error">{errors.cat_status}</Alert>}
              </select>
          </div>
        </div>
      </div>
    </div>
  ); 
}

export default EditGroup;
