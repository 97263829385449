import React, { useState, useEffect } from "react";
import axios from 'axios';
import { withRouter } from "./withRouter";
import SidebarOne from "./sidebar1";
import { useNavigate } from 'react-router-dom';

function EditProduct(props) {
  const [cards, setCards] = useState([]);
  const [cards1, setCards1] = useState([]);
  const [cards2, setCards2] = useState([]);
  const [cards3, setCards3] = useState([]);
  const [cards4, setCards4] = useState([]);

  const [rows, setRows] = useState([{ id: 1, value: "", value1: "" }]);
  const [item_id, setItemId] = useState();
  const [item_sku, setItemSku] = useState("");
  const [item_price, setItemPrice] = useState("");
  const [item_quantity, setItemQuantity] = useState("");
  const [item_barcode, setItemBarcode] = useState("GTIN");
  const [item_barcode_value, setItemBarcodeValue] = useState("");
  const [item_product, setItemProduct] = useState("");
  const [item_tax_ref, setItemTaxRef] = useState("");
  const [file, setFile] = useState(null);
  const [item_size, setItemSize] = useState("");
  const [item_brand, setItemBrand] = useState("");
  const [item_brand_name, setItemBrandname] = useState("");
  const [item_meterial, setItemMeterial] = useState("");
  const [item_organic, setItemOrganic] = useState("Yes");
  const [item_ingredients, setItemIngredients] = useState("");
  const [item_expiry_date, setItemExpirydate] = useState("");
  const [item_category, setItemCategory] = useState("");
  const [item_subcategory, setItemSubcategory] = useState("");
  const [item_subcat, setItemSubcat] = useState("");
  const [item_pres, setItempres] = useState("");
  const [validationMessage, setValidationMessage] = useState("");

  const [validationMessage1, setValidationMessage1] = useState("");

  const [validationMessage2, setValidationMessage2] = useState("");

  const [validationMessage3, setValidationMessage3] = useState("");

  const [validationMessage4, setValidationMessage4] = useState("");

  const [validationMessage5, setValidationMessage5] = useState("");

  const [validationMessage6, setValidationMessage6] = useState("");


  const navigate = useNavigate();

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleAddRow = () => {
    const newRow = { id: rows.length + 1, value: "", value1: "" };
    setRows([...rows, newRow]);
  };

  const handleChange = (id, e) => {
    const updatedRows = rows.map(row => {
      if (row.id === id) {
        return { ...row, value: e.target.value };
      }
      return row;
    });
    setRows(updatedRows);
  };

  const handleChange1 = (id, e) => {
    const updatedRows = rows.map(row => {
      if (row.id === id) {
        return { ...row, value1: e.target.value };
      }
      return row;
    });
    setRows(updatedRows);
  };

  const handleRemoveRow = id => {
    const updatedRows = rows.filter(row => row.id !== id);
    setRows(updatedRows);
  };

  const api_url = localStorage.getItem('api_url');
  const product_id = localStorage.getItem('product_id');

  useEffect(() => {
    const fetchCards = async () => {
      try {
        const response = await axios.get(`${api_url}/admingetProduct/${product_id}`);
        const data = response.data;
        setCards(data.categories);
        setCards2(data.brand);
        setItemId(data.product.item_id);
        setItemSku(data.product.item_sku);
        setItemPrice(data.product.price);
        setItemQuantity(data.product.qty);
        setItemBarcode(data.product.barcode_type);
        setItemBarcodeValue(data.product.barcode_value);
        setItemTaxRef(data.product.tax_reference);
        setItemProduct(data.product.product_name);
        setItemBrandname(data.product.brand_id);
        setFile(data.product.product_image);
        setItemBrand(data.product_attribute.brand);
        setItemMeterial(data.product_attribute.material);
        setItemIngredients(data.product_attribute.ingredients);
        setItemExpirydate(data.product_attribute.expirydate);
        setItemSize(data.product_attribute.size);
        setItemIngredients(data.product_attribute.organiccertified);
        setItemCategory(data.product.category);
        setItemSubcategory(data.product.sub_category);
        setItempres(data.product.prescription);
        setItemSubcat(data.product.sub_cat);
        setCards4(data.product_spec);

      } catch (error) {
        console.error("Error fetching card data:", error);
      }
    };
    fetchCards();
  }, [api_url, product_id]);


  const validateForm = () => {
    if (!item_pres) {
      setValidationMessage("Prescription field is required.");
      return false;
    }
    
    if (!item_product) {
      setValidationMessage1("Product Name is required.");
      return false;
    }
  
    if (!item_price) {
      setValidationMessage2("Price field is required.");
      return false;
    }
  
    if (!item_quantity) {
      setValidationMessage3("Quantity field is required.");
      return false;
    }
  
    if (!item_brand_name) {
      setValidationMessage4("Brand field is required.");
      return false;
    }
  
    setValidationMessage("");
    setValidationMessage1("");
    setValidationMessage2("");setValidationMessage3("");setValidationMessage4("");
    return true;
  };

  const addproductdetails = () => {

    if (!validateForm()) {
      return;
    }
    const formData = new FormData();
    const product_id = localStorage.getItem('product_id');
    formData.append("product_id", product_id);
    formData.append("item_id", item_id);
    formData.append("item_sku", item_sku);
    formData.append("price", item_price);
    formData.append("quantity", item_quantity);
    formData.append("barcode_type", item_barcode);
    formData.append("barcode_value", item_barcode_value);
    formData.append("product_name", item_product);
    formData.append("tax_reference", item_tax_ref);
    formData.append("product_size", item_size);
    formData.append("brand_name", item_brand);
    formData.append("brand_id", item_brand_name);
    formData.append("meterial", item_meterial);
    formData.append("organic", item_organic);
    formData.append("ingredients", item_ingredients);
    formData.append("expiry_date", item_expiry_date);
    formData.append("category", item_category);
    formData.append("subcategory", item_subcategory);
    formData.append("prescription", item_pres);
    if (file) {
      formData.append("pic", file);
    }
    rows.forEach(row => {
      formData.append(`rows[${row.id}][key]`, row.value);
      formData.append(`rows[${row.id}][value]`, row.value1);
    });

    axios({
      method: "post",
      url: api_url + "/updateproductdetails",
      data: formData
    })
      .then((res) => {
        console.log("Product added successfully:", res.data);
        alert("Product updated successfully");
        navigate("/Products", { replace: true });
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error adding product:", error);
      });
  };
         
  
   
    return (
      <div className="view_pro" style={{ display: 'flex' }}>
        <div>
          <SidebarOne />
        </div>
  
        <div className="pr_names">
          <><div className="pro_names" style={{ display: 'flex' }}>
        <p className="p_name">Products </p>
        <p className="act_name" style={{ textAlign: 'left' }}>Active</p>
      </div><hr></hr><div className="product_detail">
          <div style={{ display: 'flex' }}>

            <div className="item_details">
              <div style={{ display: 'flex' }}>
                <img className="itme_img" src={require("../assets/images/hash.png")} />
                <p className="item_name">Item ID</p>
              </div>
              <input type="text" className="item_input" name="item_id" value={item_id} onChange={(e) => setItemId(e.target.value)} />
            </div>


            <div className="stock_details">
              <div style={{ display: 'flex' }}>
                <div className="vl"></div>
                <div className="itme_d">
                  <div style={{ display: 'flex' }}>
                    <img className="itme_img" src={require("../assets/images/command.png")} />
                    <p className="item_name">Stock Code (SKU)</p>
                  </div>
                  <input type="text" className="item_input" name="item_sku" value={item_sku} onChange={(e) => setItemSku(e.target.value)} />
                </div>
              </div>
            </div>

            <div className="barcode_details">
              <div style={{ display: 'flex' }}>
                <div className="vl"></div>
                <div className="itme_d">
                  <div style={{ display: 'flex' }}>
                    <img className="itme_img" src={require("../assets/images/maximize.png")} />
                    <p className="item_name">Barcode Type</p>
                  </div>
                  <input readOnly type="text" className="item_input" name="item_barcode" value={item_barcode} onChange={(e) => setItemBarcode(e.target.value)} />
                </div>
              </div>
            </div>

            <div className="barvalue_details">
              <div style={{ display: 'flex' }}>
                <div className="vl"></div>
                <div className="itme_d">
                  <div style={{ display: 'flex' }}>
                    <img className="itme_img" src={require("../assets/images/barcode.png")} />
                    <p className="item_name">Barcode Value</p>
                  </div>
                  <input type="text" className="item_input" name="item_barcode_value" value={item_barcode_value} onChange={(e) => setItemBarcodeValue(e.target.value)} />
                </div>
              </div>
            </div>
            <button className="itme_img1" onClick={() => addproductdetails(rows)}>Update</button>

          </div>

          <hr></hr>

          <div style={{ display: 'flex' }}>

            <div className="item_details">
              <div style={{ display: 'flex' }}>
                <img className="itme_img" src={require("../assets/images/type.png")} />
                <p className="item_name">Item Title</p>
              </div>
              <input type="text" className="item_input1" name="item_productname" value={item_product} onChange={(e) => setItemProduct(e.target.value)} />
              {validationMessage1 && <p className="validation-message">{validationMessage1}</p>}
            </div>
            <div className="tax_ref_details">
              <div style={{ display: 'flex' }}>
                <div className="vl"></div>
                <div className="itme_d">
                  <div style={{ display: 'flex' }}>
                    <img className="itme_img" src={require("../assets/images/hash.png")} />
                    <p className="item_name">Tax Reference Code</p>
                  </div>
                  <input type="text" className="item_input" name="item_tax_ref" value={item_tax_ref} onChange={(e) => setItemTaxRef(e.target.value)} />
                </div>
              </div>
            </div>

            <div className="tax_ref_details">
              <div style={{ display: 'flex' }}>
                <div className="vl"></div>
                <div className="itme_d">
                  <div style={{ display: 'flex' }}>
                    <img className="itme_img" src={require("../assets/images/hash.png")} />
                    <p className="item_name">prescription</p>
                  </div>
                    <select className="item_input" value={item_pres} onChange={(e) => setItempres(e.target.value)}>
                    <option value="">Select</option>
                    <option value="1" selected={item_pres === 1}>Yes</option>
                    <option value="2" selected={item_pres === 2}>No</option>
                    </select>
            {validationMessage && <p className="validation-message">{validationMessage}</p>}
                </div>
              </div>
            </div>
          </div>

          <hr></hr>

<div style={{ display: 'flex' }}>

  <div className="item_details">
    <div style={{ display: 'flex' }}>
      <img className="itme_img" src={require("../assets/images/type.png")} />
      <p className="item_name">Item Price</p>
    </div>
    <input type="text" className="item_input" name="item_productname" value={item_price} onChange={(e) => setItemPrice(e.target.value)} />
    {validationMessage2 && <p className="validation-message">{validationMessage2}</p>}
  </div>
  <div className="tax_ref_details1">
    <div style={{ display: 'flex' }}>
      <div className="vl"></div>
      <div className="itme_d">
        <div style={{ display: 'flex' }}>
          <img className="itme_img" src={require("../assets/images/hash.png")} />
          <p className="item_name">Item Quantity</p>
        </div>
        <input type="text" className="item_input" name="item_tax_ref" value={item_quantity} onChange={(e) => setItemQuantity(e.target.value)} />
        {validationMessage3 && <p className="validation-message">{validationMessage3}</p>}
      </div>
    </div>
  </div>


  <div className="tax_ref_details1">
    <div style={{ display: 'flex' }}>
      <div className="vl"></div>
      <div className="itme_d">
        <div style={{ display: 'flex' }}>
          <img className="itme_img" src={require("../assets/images/hash.png")} />
          <p className="item_name">Brand Name</p>
        </div>
        <select  className="item_input1" name="item_tax_ref" value={item_brand_name} onChange={(e) => setItemBrandname(e.target.value)} >
         <option value="">Select a Brand Name</option>
          {cards2.map((card2) => (
            <option key={card2._id} value={card2._id}  selected={card2.brand_name === item_brand_name}>
              {card2.brand_name}
            </option>
              ))}
        </select>
        {validationMessage4 && <p className="validation-message">{validationMessage4}</p>}
      </div>
    </div>
  </div>
</div>
        </div><br></br><div className="media">
          <div className="pro_de_ref">
            <p><b>Media</b></p>
            <hr />
            <br></br>
            <p className="img_and_vid"><b>Images & Videos</b></p> 
            <input type="file" onChange={handleFileChange} />
            {file && <img src={file} alt="Uploaded File" />}
          </div>

        </div><br></br><div className="product_sepc pro_de_ref">
          <p><b>Product Specifications</b></p>
          <hr />
          <p><b>General</b></p>
          <hr />
          <button className="add_Row" onClick={handleAddRow}> + Add Specifications</button>
          <table>
            
            <tbody>
            {cards4.map(c =>(
               <tr key={c._id}>
                 
               <td ><input
                   type="text"
                   className="key_row"
                   value={c.spec_key}
                   onChange={e => handleChange(c._id, e)} /></td>

               <td><input
                   type="text"
                   className="value_row"
                   value={c.spec_value}
                   onChange={e => handleChange1(c._id, e)} /></td>
               <td>
                 {/* <button className="remove_row" onClick={() => handleRemoveRow(c._id)}>X</button> */}
               </td>
             </tr>

            ))}

              {rows.map(row => (
                <tr key={row.id}>
                 
                  <td ><input
                      type="text"
                      className="key_row"
                      value={row.value}
                      onChange={e => handleChange(row.id, e)} /></td>

                  <td><input
                      type="text"
                      className="value_row"
                      value={row.value1}
                      onChange={e => handleChange1(row.id, e)} /></td>
                  <td>
                    <button className="remove_row" onClick={() => handleRemoveRow(row.id)}>X</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div><><br></br><div className="item_hi pro_de_ref">
          <p><b>Item Hierarchy</b></p>
          <hr />

          {/* <p>Category</p>
          <select name="category_name" value={item_category} onChange={(e) => setItemCategory(e.target.value)} className="item_input3">
              <option value="">Select a Category</option>
              {cards.map((card) => (
                <option key={card.cat_id} value={card.cat_id}>{card.cat_name}</option>
              ))}
            </select>

            <p>Sub category</p>

            <select name="category_name" value={item_subcategory} onChange={(e) => setItemSubcategory(e.target.value)} className="item_input3">
              <option value="">Select a Sub Category</option>
              {cards1.map((card1) => (
                <option key={card1._id} value={card1._id}>{card1.sub_cat_name}</option>
              ))}
            </select> */}


<div className="item_details">
            <div style={{ display: 'flex' }}>
              <img className="itme_img" src={require("../assets/images/command.png")} alt="Category" />
              <p className="item_name">Category</p>
            </div>
            <select name="item_category" className="item_input" value={item_category} onChange={(e) => {
              setItemCategory(e.target.value);
              // Fetch subcategories when category changes
              if (e.target.value) {
                axios.get(`${api_url}/getSubcategories/${e.target.value}`)
                  .then(response => {
                    setCards1(response.data.sub_details1);
                    setItemSubcategory(""); // Clear subcategory when category changes
                  })
                  .catch(error => {
                    console.error("Error fetching subcategories:", error);
                  });
              } else {
                setCards1([]);
                setItemSubcategory("");
              }
            }}>
              <option value="">Select Category</option>
              {cards.map((category) => (
                <option key={category.cat_id} value={category.cat_id}>{category.cat_name}</option>
              ))}
            </select>
          </div>

          <div className="item_details">
            <div style={{ display: 'flex' }}>
              <img className="itme_img" src={require("../assets/images/hash.png")} alt="Subcategory" />
              <p className="item_name">Subcategory</p>
            </div>
            <select
        name="item_subcategory"
        className="item_input"
        value={item_subcategory}
        onChange={(e) => setItemSubcategory(e.target.value)}
      >
        <option value="">Select Subcategory</option>
        {cards1.length === 0 ? (
          <option value={item_subcategory}>{item_subcat}</option>
        ) : (
          cards1.map((subcategory) => (
            <option key={subcategory.sub_cat_id} value={subcategory.sub_cat_id}>
              {subcategory.sub_cat_name}
            </option>
          ))
        )}
      </select>
          </div>

        </div><br></br><div className="attributes pro_de_ref">
            <p><b>Attributes</b></p>
            <hr />
            <table style={{ borderCollapse: 'collapse', width: '100%', 'margin-top': '-17px' }}>
              <thead>
                <tr>
                  <th className="tab_itmes">
                   <p>Size :</p>                         
                   <select className="item_input" name="item_size" value={item_size} onChange={(e) => setItemSize(e.target.value)} >
                    <option value="">Select Size</option>
                   <option value="Large">Large</option>
                <option value="Medium">Medium</option>
                <option value="Small">Small</option>
                       </select>
                  </th>

                  <th className="tab_itmes">
                   <p>Material  : </p>    
                   <input type="text" className="item_input" name="item_meterial" value={item_meterial} onChange={(e) => setItemMeterial(e.target.value)} />

                  </th>
                </tr>
                <tr>
                  <th className="tab_itmes">
                    <p>Brand  : </p>
                    <input type="text" className="item_input" name="item_brand" value={item_brand} onChange={(e) => setItemBrand(e.target.value)} />

                  </th>

                  <th className="tab_itmes">
                   <p> Ingredients  :</p> 
                   <input type="text" className="item_input2" name="item_ingredients" value={item_ingredients} onChange={(e) => setItemIngredients(e.target.value)} />

                  </th>
                </tr>
                <tr>
                  <th className="tab_itmes">
                    <p>Organic Certified</p>
                  <select type="text" className="item_input" name="item_organic" value={item_organic} onChange={(e) => setItemOrganic(e.target.value)} >
                   <option>Yes</option>
                   <option>No</option>
                    </select>

                  </th>

                  <th className="tab_itmes">
                   <p> Expiry Date :</p> 
                   <input type="date" className="item_input" name="item_expiry_date" value={item_expiry_date} onChange={(e) => setItemExpirydate(e.target.value)} />

                  </th>
                </tr>
              </thead>

            </table>
          </div><br></br></></>
        </div>
      </div>
    ); 
}

export default withRouter(EditProduct);
