import React from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Link, NavLink } from 'react-router-dom';
import '../assets/css/dashboard.css';
import axios from 'axios';
import SidebarOne from "../components/sidebar1";
import InnerHeader from "../components/InnerHeader";
import CanvasJS from '@canvasjs/charts';

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      api_url: localStorage.getItem('api_url'),
      data: [],
      data1: [],
      data2: [],
      data3: []
    };
  }

  componentDidMount() {
    axios.get(`${this.state.api_url}/getCounts`).then(res => {
      this.setState({ data: res.data.counts });
    });

    axios.get(`${this.state.api_url}/getCountsuser`).then(res => {
      this.setState({ data1: res.data.counts1 });
    });

    axios.get(`${this.state.api_url}/getCountsStores`).then(res => {
      this.setState({ data2: res.data.counts1 });
    });

    axios.get(`${this.state.api_url}/getCountsAgents`).then(res => {
      this.setState({ data3: res.data.counts1 });
    });


    axios({
      method: 'get',
      url: this.state.api_url + '/getSalesData',
    }).then(res => {
      this.setState({ salesData: res.data }, () => {
        this.renderChart(); // Render chart after data is set
      });
    });

    axios({
      method: 'get',
      url: this.state.api_url + '/getSalesstoreData',
    }).then(res => {
      this.setState({ salesData1: res.data }, () => {
        this.renderChart1(); // Render chart after data is set
      });
    });

    axios({
      method: 'get',
      url: this.state.api_url + '/getSalesproductData',
    }).then(res => {
      this.setState({ salesData2: res.data }, () => {
        this.renderChart2(); // Render chart after data is set
      });
    });

  }

  renderChart() {
    var chart = new CanvasJS.Chart("chartContainer", {
      title: {
        text: "Year and Month wise Revenue Generated"
      },
      axisY: {
        title: "Revenue (₹)",
        includeZero: true
      },
      data: [{
        type: "column",
        dataPoints: this.state.salesData
      }]
    });

    chart.render(); // Render the chart
  }

  renderChart1() {
    var chart = new CanvasJS.Chart("chartContainer1", {
      title: {
        text: "Best Selling Stores"
      },
      axisY: {
        title: "Revenue (₹)",
        includeZero: true
      }, 
      data: [{
        type: "column",
        dataPoints: this.state.salesData1
      }]
    });

    chart.render(); // Render the chart
  }

  renderChart2() {
    var chart = new CanvasJS.Chart("chartContainer2", {
      title: {
        text: "Top Selling Products"
      },
      axisY: {
        title: "Revenue (₹)",
        includeZero: true
      },
      data: [{
        type: "column",
        dataPoints: this.state.salesData2
      }]
    });

    chart.render(); // Render the chart
  }

  render() {
    return (
      <div className="dashboard">
        <SidebarOne />
        <InnerHeader />
        <hr></hr>
        <div className='dashboard_hash' style={{ marginLeft: '20%', backgroundColor: '#F9F9FC' }}>
          <p>Dashboard</p>
          <div className='dashboard_layouts'>
            <div className="col-md-4">
              <div className="overlap-group-6">
                <div className="sales-today-5">User Management</div>
                <div className="row" style={{ marginTop: "20px" }}>
                  <div className="col-md-4">
                    <div className="sales-today-copy-5">{this.state.data1.customerc}</div>
                    <div className="sales-today-3">Total</div>
                  </div>
                  <div className="col-md-4 lineimg">
                    <div className="sales-today-copy-5">{this.state.data1.customerc}</div>
                    <div className="sales-today-3 act">Active</div>
                  </div>
                  <div className="col-md-4">
                    <div className="sales-today-copy-5">0</div>
                    <div className="sales-today-3 iact">Inactive</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="overlap-group-6">
                <div className="sales-today-5">Stores</div>
                <div className="row" style={{ marginTop: "20px" }}>
                  <div className="col-md-4">
                    <div className="sales-today-copy-5">{this.state.data2.storesc}</div>
                    <div className="sales-today-3">Total</div>
                  </div>
                  <div className="col-md-4 lineimg">
                    <div className="sales-today-copy-5">{this.state.data2.storesc}</div>
                    <div className="sales-today-3 act">Active</div>
                  </div>
                  <div className="col-md-4">
                    <div className="sales-today-copy-5">0</div>
                    <div className="sales-today-3 iact">Inactive</div>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div className='dash_charts'>
            <div className="col-md-6">
              <div className="overlap-group-6">
                <div className="sales-today-5">Year and Month wise Revenue Generated</div>
                <div id="chartContainer">
                  {/* The chart will render here */}
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="overlap-group-6">
                <div className="sales-today-5">Best Selling Stores</div>
                <div id="chartContainer1">
                  {/* The chart will render here */}
                </div>
              </div>
            </div>
          </div>

          <div className='dash_chart1'>
            <div className="col-md-12">
              <div className="overlap-group-6">
                <div className="sales-today-5">Top Selling Products</div>
                <div id="chartContainer2">
                  {/* The chart will render here */}
                </div>
              </div>
            </div>
          </div>

<br></br>

          {/* <div className='dash_chart1'>
            <div className="col-md-12">
              <div className="overlap-group-6">
                <div className="sales-today-5">Monthly Sales analysis</div>
                <div id="chartContainer3">
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    );
  }
}

export default Dashboard;
