import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import axios from "axios";
import * as XLSX from 'xlsx';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-responsive-modal';

const DatatableAssign = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [selectedStatus, setSelectedStatus] = useState(""); // State for selected status
  const [sortField, setSortField] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [pic, setPic] = useState([]);
  const [filename, setFilename] = useState([]);
  const [sortColumn, setSortColumn] = useState(null);
  const [allSelected, setAllSelected] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const api_url = localStorage.getItem('api_url_api');
      const res = await axios.get(api_url + '/getorderassign');
      const data = await res.data.cartItems;
      setData(data);
      setFilteredData(data);
    };

    fetchData();
  }, []);

  // Handle filter text change
  const handleFilterChange = (e) => {
    const value = e.target.value || "";
    setFilterText(value);
    filterData(value, selectedStatus, sortField, sortDirection);
  };

  // Handle status filter change
  const handleStatusChange = (e) => {
    const status = e.target.value || "";
    setSelectedStatus(status);
    filterData(filterText, status, sortField, sortDirection);
  };

  // Filter data based on search text and status
  const filterData = (filterValue, statusValue, sortField, sortDirection) => {
    const filteredItems = data.filter((item) => {
      const matchesFilter = Object.keys(item).some(
        (key) =>
          key !== "id" &&
          item[key] &&
          item[key].toString().toLowerCase().includes(filterValue.toLowerCase())
      );
      const matchesStatus = !statusValue || item.status === statusValue;
      return matchesFilter && matchesStatus;
    });

    if (sortField && sortDirection) {
      filteredItems.sort((a, b) => {
        const fieldA = (a[sortField] || "").toString().toLowerCase();
        const fieldB = (b[sortField] || "").toString().toLowerCase();
        return sortDirection === "asc" ? fieldA.localeCompare(fieldB) : fieldB.localeCompare(fieldA);
      });
    }

    setFilteredData(filteredItems);
  };

  const handleSelectAll = (isChecked) => {
    setAllSelected(isChecked);
    if (isChecked) {
      setSelectedRows(filteredData);
    } else {
      setSelectedRows([]);
    }
  };

  const handleRowSelected = (isChecked, row) => {
    setSelectedRows((prevSelectedRows) => {
      if (isChecked) {
        return [...prevSelectedRows, row];
      } else {
        return prevSelectedRows.filter((selectedRow) => selectedRow.id !== row.id);
      }
    });
  };

  const navigate = useNavigate();

  const exportSelectedRows = () => {
    const selectedRowsData = selectedRows.map(row => {
      return {
        'product Name': row.product_name,
        'payment Id': row.pay_success,
        'Order Id': row.order_id,
        'customer Name': row.username,
        'Store name': row.store_name,
        'Delivery Patner Name': row.agent_name,
      };
    });

    const worksheet = XLSX.utils.json_to_sheet(selectedRowsData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "SelectedRows");
    XLSX.writeFile(workbook, "order placed & assign.xlsx");
  };

  const columns = [
    {
      name: <input type="checkbox" onChange={(e) => handleSelectAll(e.target.checked)} checked={allSelected} />,
      cell: (row) => (
        <input
          type="checkbox"
          onChange={(e) => handleRowSelected(e.target.checked, row)}
          checked={selectedRows.map((selectedRow) => selectedRow.order_id).includes(row.order_id)}
        />
      ),
    },
    {
      name: 'Product Name',
      selector: (row) => (row && row.product_name) || '',
      sortable: true,
    },
    {
      name: 'Payment Id',
      selector: (row) => (row && row.pay_success) || '',
      sortable: true,
    },
    {
      name: 'Order Id',
      selector: (row) => (row && row.order_id) || '',
      sortable: true,
    },
    {
      name: 'Customer Name',
      selector: (row) => (row && row.username) || '',
      sortable: true,
    },
    {
      name: 'Store Name',
      selector: (row) => (row && row.store_name) || '',
      sortable: true,
    },
    {
      name: 'Delivery Patner Name',
      selector: (row) => (row && row.agent_name) || '',
      sortable: true,
    },
    {
      name: 'Status',
      selector: (row) => (row && row.status) || '',
      sortable: true,
    }
  ];

  return (
    <div>
      <div className="pro_buttons">
        <select className="filter_status" onChange={handleStatusChange} value={selectedStatus}>
          <option value="">Select Status</option>
          <option value="order_placed">Order Placed</option>
          <option value="packing">Packing</option>
          <option value="dispatched">Dispatched</option>
          <option value="pickup">Pickup</option>
          <option value="delivered">Delivered</option>
        </select>
        <button className="export_tem" onClick={exportSelectedRows}>EXPORT</button>
      </div>
      <p className="produc_de">Order Assign Details</p>
      <div className="data_margin">
        <DataTable
          columns={columns}
          data={filteredData}
          pagination
          paginationPerPage={5}
          paginationRowsPerPageOptions={[5, 10, 15, 50, 100, 500, 1000, 10000]}
          highlightOnHover
          sortServer={false}
          customStyles={{
            headCells: { style: { fontWeight: "bold" } },
            rows: { style: { "&:nth-of-type(odd)": { backgroundColor: "#f3f3f3" } } }
          }}
        />
      </div>
    </div>
  );
};

export default DatatableAssign;
