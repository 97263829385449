import React, { useState, useEffect } from "react";
import axios from 'axios';
import { withRouter } from "./withRouter";
import SidebarOne from "./sidebar1";
import { useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';

function CreateBrand(props) {
  const [brand_name, setBrandName] = useState("");
  const [file, setFile] = useState(null);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const api_url = localStorage.getItem('api_url');

  const validateInputs = () => {
    const errors = {};
    if (!brand_name) {
      errors.brand_name = "Brand name is required";
    }
    if (!file) {
      errors.file = "Brand image is required";
    }
    return errors;
  };

  const addproductdetails = () => {
    const validationErrors = validateInputs();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
  const store_id = localStorage.getItem('store_id');
    const formData = new FormData();
    formData.append("brand_name", brand_name);
    formData.append("pic", file);
    formData.append("store_id", store_id);

    axios({
      method: "post",
      url: api_url + "/addBrands",
      data: formData
    })
      .then((res) => {
        alert("Brand added successfully");
        navigate("/Brands", { replace: true });
      })
      .catch((error) => {
        console.error("Error adding brand:", error);
      });
  };

  useEffect(() => {
    const fetchCards = async () => {
      // Any initial fetching logic can go here if needed
    };
    fetchCards();
  }, []); // Empty dependency array to run the effect only once on mount

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  return (
    <div className="view_pro" style={{ display: 'flex' }}>
      <div>
        <SidebarOne />
      </div>
      <div className="pr_names">
        <div className="pro_names" style={{ display: 'flex' }}>
          <p className="p_name">Brands </p>
          <p className="act_name" style={{ textAlign: 'left' }}>Active</p>
        </div>
        <hr></hr>
        <div className="product_detail">
          <div className="item_details">
            <div style={{ display: 'flex' }}>
              <p className="item_name">Brand Name</p>
              <button className="itme_img11" onClick={addproductdetails}>Save</button>
            </div>
            <input
              type="text"
              className="item_input_cat"
              value={brand_name}
              onChange={(e) => setBrandName(e.target.value)}
            />
            {errors.brand_name && <Alert severity="error">{errors.brand_name}</Alert>}
          </div>
          <br />
          <div className="item_details">
            <div style={{ display: 'flex' }}>
              <p className="item_name">Brand Image</p>
            </div>
            <input type="file" onChange={handleFileChange} />
            {errors.file && <Alert severity="error">{errors.file}</Alert>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(CreateBrand);
