import React, { useState, useEffect } from "react";
import { Link, NavLink, useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import axios from 'axios';
import SidebarOne from "./sidebar1";
import { withRouter } from "./withRouter";

function CreateGroup(props) {
  const [cat_name, setCatName] = useState("");
  const [cat_description, setCatDescription] = useState("");
  const [cat_status, setCatStatus] = useState("");
  const [file, setFile] = useState(null);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const api_url = localStorage.getItem('api_url');

  const validateInputs = () => {
    const errors = {};
    if (!cat_name) {
      errors.cat_name = "Category name is required";
    }
    if (!cat_description) {
      errors.cat_description = "Category description is required";
    }
    if (!cat_status) {
      errors.cat_status = "Status is required";
    }
    if (!file) {
      errors.file = "Category image is required";
    }
    return errors;
  };

  const addproductdetails = () => {
    const validationErrors = validateInputs();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const formData = new FormData();
    const cat_id = localStorage.getItem('cat_id');
    const store_id = localStorage.getItem('store_id');

    formData.append("cat_id", cat_id);
    formData.append("cat_name", cat_name);
    formData.append("cat_description", cat_description);
    formData.append("status", cat_status);
    formData.append("pic", file);
    formData.append("store_id", store_id);


    axios({
      method: "post",
      url: api_url + "/addcatdetails",
      data: formData
    })
      .then((res) => {
        alert("Categories added successfully");
        navigate("/Groups", { replace: true });
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error adding product:", error);
      });
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  return (
    <div className="view_pro" style={{ display: 'flex' }}>
      <div>
        <SidebarOne />
      </div>

      <div className="pr_names">
        <div className="pro_names" style={{ display: 'flex' }}>
          <p className="p_name">Categories </p>
          <p className="act_name" style={{ textAlign: 'left' }}>Active</p>
        </div>

        <hr></hr>
        <div className="product_detail">
          <div className="item_details">
            <div style={{ display: 'flex' }}>
              <p className="item_name">Category Name</p>
              <button className="itme_img11" onClick={addproductdetails}>Save</button>
            </div>
            <input
              type="text"
              className="item_input_cat"
              value={cat_name}
              onChange={(e) => setCatName(e.target.value)}
            />
            {errors.cat_name && <Alert severity="error">{errors.cat_name}</Alert>}
          </div>
          <br />
          <div className="item_details">
            <div style={{ display: 'flex' }}>
              <p className="item_name">Category Description</p>
            </div>
            <textarea
              className="item_input_cat1"
              value={cat_description}
              onChange={(e) => setCatDescription(e.target.value)}
            >
              {cat_description}
            </textarea>
            {errors.cat_description && <Alert severity="error">{errors.cat_description}</Alert>}
          </div>
          <br />
          <div className="item_details">
            <div style={{ display: 'flex' }}>
              <p className="item_name">Category Image</p>
            </div>
            <input type="file" onChange={handleFileChange} />
            {errors.file && <Alert severity="error">{errors.file}</Alert>}
          </div>
          <br />
          <div className="item_details">
            <div style={{ display: 'flex' }}>
              <p className="item_name">Status</p>
            </div>
            <select
              className="item_input_cat"
              value={cat_status}
              onChange={(e) => setCatStatus(e.target.value)}
            >
              <option value="">select status</option>
              <option value="active">Active</option> 
              <option value="inactive">In Active</option>
            </select>
            {errors.cat_status && <Alert severity="error">{errors.cat_status}</Alert>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(CreateGroup);
